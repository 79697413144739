import React from "react";
import { Link } from "react-router-dom";
import coverImg from "../../static/garni-menu-tapa.jpg";
import s from "./Homepage.module.scss";

const Homepage = () => {
  return (
    <div className={s["root"]}>
      <Link to="/secciones" className={s["link"]}>
        <img src={coverImg} className={s["background-image"]} />
      </Link>
    </div>
  );
};

export default Homepage;
