import React, { useContext } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { MenuDataContext } from "../../context";

import logoRoundImg from "../../static/logo-round.png";
import logoTextImg from "../../static/logo-text.png";
import s from "./SectionsList.module.scss";

const SectionsList: React.FC<{}> = () => {
  const menuData = useContext(MenuDataContext);
  const { sections, menuOrder } = menuData!;
  return (
    <div className={s["root"]}>
      <div className={s["logo"]}>
        <img src={logoRoundImg} className={s["logo-round"]} />
        <img src={logoTextImg} className={s["logo-text"]} />
      </div>
      <nav className={s["sections"]}>
        {menuOrder.map((sectionName) => {
          const title = sections[sectionName].title;
          return (
            <Link
              to={`/secciones/${sectionName}`}
              className={classnames(s["section-link"], s[sectionName])}
            >
              <span className={s["section-link-title"]}>{title}</span>
            </Link>
          );
        })}
      </nav>
    </div>
  );
};

export default SectionsList;
