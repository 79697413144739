import { useLocation, useHistory } from "react-router-dom";
import { useTransition } from "react-spring";

export default () => {
  const location = useLocation();
  const history = useHistory();
  const isHomepage = location.pathname === "/";
  const isPopAction = history.action === "POP";

  const initialPosition = isHomepage
    ? "translate(0,0)"
    : isPopAction
    ? "translate(-100%,0)"
    : "translate(100%,0)";

  const transitions = useTransition(location, (location) => location.pathname, {
    from: {
      position: "absolute",
      width: "100%",
      opacity: 0,
      transform: initialPosition,
    },
    enter: { opacity: 1, transform: "translate(0%,0)" },
    leave: {
      opacity: 0,
      transform: isPopAction ? "translate(150%,0)" : "translate(-50%,0)",
    },
  });

  return transitions;
};
