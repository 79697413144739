import React, { useContext } from "react";
import classnames from "classnames";
import { MenuDataContext } from "../../context";
import s from "./FoodIcon.module.scss";

interface Props {
  name: string;
  size?: "small" | "medium";
}

const FoodIcon: React.FC<Props> = ({ name, size = "medium" }) => {
  const { tooltips } = useContext(MenuDataContext)!;
  return (
    <i
      className={classnames(
        s["icon"],
        s[`icon-${name}`],
        s[`icon-size-${size}`]
      )}
      title={tooltips[name]}
    />
  );
};

export default FoodIcon;
