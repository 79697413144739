import React, { useContext } from "react";
import classnames from "classnames";
import { RouteComponentProps } from "react-router";
import logoImg from "../../static/logo-round.png";
import SwipeBack from "../../components/SwipeBack";
import FoodIconsLegend from "../../components/FoodIconsLegend";
import CustomIconsLegend from "../../components/CustomIconsLegend";
import SaltLegend from "../../components/SaltLegend";
import MenuItem from "../../components/MenuItem";
import { MenuDataContext } from "../../context";
import s from "./SectionView.module.scss";

type RouteProps = RouteComponentProps<{ name: string }>;

const SectionView: React.FC<RouteProps> = ({ match }) => {
  const menuData = useContext(MenuDataContext);
  // TODO: Validate sectionName
  const sectionName = match.params.name;
  const {
    title: sectionTitle,
    items: sectionItems,
    iconsLegend,
  } = menuData!.sections[sectionName];

  return (
    <SwipeBack>
      <div className={s["root"]}>
        <img src={logoImg} className={s["logo"]} />
        <div className={classnames(s["section-title"], s[sectionName])}>
          <span className={s["section-title-inner"]}>{sectionTitle}</span>
        </div>
        <div className={s["section-content"]}>
          <ul className={s["items-list"]}>
            {sectionItems.map((item) => (
              <MenuItem item={item} />
            ))}
          </ul>
          {iconsLegend !== false && (
            <div className={s["footer"]}>
              {iconsLegend === "custom" ? (
                <CustomIconsLegend />
              ) : (
                <>
                  <FoodIconsLegend />
                  <SaltLegend />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </SwipeBack>
  );
};

export default SectionView;
