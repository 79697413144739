import React, { useContext } from "react";
import FoodIcon from "../FoodIcon";
import { MenuDataContext } from "../../context";
import s from "./FoodIconsLegend.module.scss";

const FoodIconsLegend: React.FC<{}> = () => {
  const { tooltips } = useContext(MenuDataContext)!;
  return (
    <ul className={s["root"]}>
      <li className={s["item"]}>
        <FoodIcon name="glutenFree" size="small" />
        <span className={s["title"]}>{tooltips["glutenFree"]}</span>
      </li>
      <li className={s["item"]}>
        <FoodIcon name="vegan" size="small" />
        <span className={s["title"]}>{tooltips["vegan"]}</span>
      </li>
      <li className={s["item"]}>
        <FoodIcon name="vegetarian" size="small" />
        <span className={s["title"]}>{tooltips["vegetarian"]}</span>
      </li>
    </ul>
  );
};

export default FoodIconsLegend;
