import React from "react";
import s from "./SaltLegend.module.scss";

const SaltLegend = () => (
  <div className={s["root"]}>
    <p>
      Menos sal, más vida.
      <br />
      El consumo excesivo de sal de sodio es perjudicial para la salud.
    </p>
  </div>
);

export default SaltLegend;
