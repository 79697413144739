import React from "react";
import classnames from "classnames";
import FoodIcon from "../../components/FoodIcon";
import { SectionItem } from "../../types";
import s from "./MenuItem.module.scss";

interface Props {
  item: SectionItem;
}

const MenuItem: React.FC<Props> = ({
  item: { title, description, price, icons },
}) => {
  const subTextPtn = /(.+?) \*(.+)\*$/;
  const titleSubText = title.match(subTextPtn);
  const isDoublePrice = typeof price === "string" && Boolean(price);
  const finalPrice = isDoublePrice ? price.toString().split(" / ") : price;
  const listDescription = description.split("* ");
  const isListDescription = listDescription.length > 1;
  return (
    <li className={s["root"]}>
      <div className={s["details"]}>
        <h2>
          {titleSubText ? (
            <>
              <span>{titleSubText[1]}</span>
              <span className={s["title-subtext"]}>{titleSubText[2]}</span>
            </>
          ) : (
            title
          )}
        </h2>
        {isListDescription ? (
          <ul className={s["list-description"]}>
            {listDescription.map((d) => d && <li>• {d}</li>)}
          </ul>
        ) : (
          <p>{description}</p>
        )}
        {icons && (
          <div className={s["food-icons"]}>
            {icons.map((iconName) => (
              <FoodIcon name={iconName} />
            ))}
          </div>
        )}
      </div>
      {finalPrice && (
        <div
          className={classnames(s["price"], isDoublePrice && s["double-price"])}
        >
          {Array.isArray(finalPrice)
            ? finalPrice.map((p) => <span>${p}</span>)
            : `\$${finalPrice}`}
        </div>
      )}
    </li>
  );
};

export default MenuItem;
