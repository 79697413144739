import React from "react";
import Hammer from "react-hammerjs";
import { useHistory } from "react-router-dom";

const SwipeBack: React.FC<{}> = ({ children }) => {
  const history = useHistory();
  return (
    <Hammer onSwipe={() => history.goBack()} direction="DIRECTION_RIGHT">
      {children}
    </Hammer>
  );
};

export default SwipeBack;
