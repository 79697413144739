import React from "react";
import FoodIcon from "../FoodIcon";
import s from "./CustomIconsLegend.module.scss";

const CustomIconsLegend = () => (
  <div className={s["root"]}>
    <span className={s["text"]}>* Con variantes, apto para:</span>
    <FoodIcon name="glutenFree" />
    <FoodIcon name="vegan" />
    <FoodIcon name="vegetarian" />
  </div>
);

export default CustomIconsLegend;
