import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { animated } from "react-spring";
import axios from "axios";
import { usePageTransition } from "./hooks";
import { MenuDataContext } from "./context";
import { MenuData } from "./types";
import Homepage from "./pages/Homepage";
import SectionsList from "./pages/SectionsList";
import SectionView from "./pages/SectionView";

import "./App.css";

function App() {
  const transitions = usePageTransition();
  const [menuData, setMenuData] = useState<MenuData | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    axios
      .get("/menu.json")
      .then((response) => {
        setMenuData(response.data as MenuData);
        setIsFetching(false);
      })
      .catch(() => {
        // TODO: Handle fetching errors
      });
  }, []);

  if (!menuData || isFetching) {
    return <div />;
  }

  return (
    <MenuDataContext.Provider value={menuData}>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>
            <Route path="/secciones/:name" component={SectionView} />
            <Route path="/secciones" component={SectionsList} />
            <Route path="/" component={Homepage} />
          </Switch>
        </animated.div>
      ))}
    </MenuDataContext.Provider>
  );
}

export default App;
